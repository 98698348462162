.overview-card {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &,
  p {
    font-size: 14px;
  }

  p,
  l {
    padding: 5px 16px;
  }

  b {
    margin: 0 5px;
    text-wrap: nowrap;
  }

  table {
    th,
    tr,
    td {
      padding: 5px;
    }
  }

  .report-map,
  .report-map + div {
    width: 100%;
  }

  .report-map {
    margin: 10px 16px;
    height: 700px;
    outline: unset;
    & * {
      cursor: default;
    }
  }

  .section {
    padding: 5px 16px;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .card-header {
      padding-top: 0;
    }
  }

  .country-overview {
    font-size: 18px;
  }

  .broadband-infrastructures {
    p {
      & + div {
        width: 100%;
        max-width: 430px;
        padding: 0 16px;
      }
    }
  }

  div + div {
    -webkit-box-flex: unset;
    -webkit-flex-grow: unset;
    -ms-flex-positive: unset;
    flex-grow: unset;
  }

  .regional-demand li {
    display: block;
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
    .report-map,
    .report-map + div {
      width: 50%;
    }

    .report-map {
      height: 775px;
      align-self: center;
      margin-right: 0;
    }

    .section .card-header {
      padding: 6px 0 16px 0;
      & > div > span {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 868px) {
    .report-map {
      height: 711px;
    }
  }
}
