@import "../../variables.scss";

.panel-header {
  width: calc(100% - 16px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  background-color: $white;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  .panel-title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }
}
