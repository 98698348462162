@import "../../../variables.scss";

body #menu- .active-multiselect-value {
  background-color: rgba(29, 118, 210, 0.3);
}

.subnational-level {
  background-color: rgba(255, 255, 255, 0.5) !important;
  page-break-before: always;
  .form-container {
    display: flex;
    flex-direction: column;
    & > div {
      margin-bottom: 20px;
    }

    .select-subnational-level {
      .controller-title {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
      }

      label {
        margin-left: 0;
        & > span {
          margin-right: 3px;
          padding: 2px;
          font-size: 16px;
        }
      }
    }

    .multiselect-form {
      label {
        display: flex;
        flex-direction: column;
        position: unset;
        font-weight: 500;
        font-size: 18px;
        margin-top: 0;
        color: $main-color;
        transform: unset;
        -webkit-transform: unset;
        -moz-transform: unset;
        -ms-transform: unset;
      }

      .multiselect-input {
        max-width: 700px;
        margin-top: 10px;
        transition: all $transition;
        background-color: rgba(255, 255, 255, 0.5);
        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }

        & > div {
          padding: 10px 14px;
        }
      }

      .result-chip {
        font-size: 12px;
        svg {
          color: $blue;
          color: currentColor;
        }

        &:hover,
        &:focus {
          svg {
            color: $white;
            color: currentColor;
          }
        }
      }
    }
  }

  .affordability-legends {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .custom-legends {
      margin-top: 0;
    }

    .affordability-2020 > p,
    .affordability-2030 > p {
      font-weight: 700;
      padding-left: 10px;
      margin-top: 10px;
    }
  }

  h3 + .affordability-legends h4 {
    margin-top: 0;
  }

  .affordability-legends + div {
    padding: 0 10px;
    width: unset !important;
  }

  .legend-container {
    .rural-color-fixed-broadband-subscriptions,
    .female-color-fixed-broadband-subscriptions,
    .female-color-internet-users {
      background-color: #fad876;
    }

    .urban-color-fixed-broadband-subscriptions,
    .male-color-fixed-broadband-subscriptions,
    .male-color-internet-users {
      background-color: #e44c19;
    }

    .rural-color-mobile-broadband-suscriptions,
    .female-color-mobile-broadband-suscriptions {
      background-color: #c7e9b4;
    }

    .urban-color-mobile-broadband-suscriptions,
    .male-color-mobile-broadband-suscriptions {
      background-color: #225ea8;
    }

    span[class="color 256-kbit/s-to-2-mbit/s-color-2020"],
    .minimum-color-fixed-plans,
    span[class="color 1gb-color-fixed-plans"] {
      background-color: #5eb4af;
    }

    span[class="color 2-mbit/s-to-10-mbit/s-color-2020"],
    .average-color-fixed-plans,
    span[class="color 5gb-color-fixed-plans"] {
      background-color: #2e96ff;
    }

    span[class="color above-10-mbit/s-color-2020"],
    .maximum-color-fixed-plans,
    span[class="color 10gb-color-fixed-plans"] {
      background-color: #b800d8;
    }

    span[class="color 256-kbit/s-to-2-mbit/s-color-2030"],
    span[class="color 1gb-color-mobile-broadband-plans"] {
      background-color: #67459b;
    }

    span[class="color 2-mbit/s-to-10-mbit/s-color-2030"],
    span[class="color 5gb-color-mobile-broadband-plans"] {
      background-color: #3753c8;
    }

    span[class="color above-10-mbit/s-color-2030"],
    span[class="color 10gb-color-mobile-broadband-plans"] {
      background-color: #1e388c;
    }

    span[class="color 2020-color-2020"] {
      background-color: #5eb4af;
    }

    span[class="color 2030-color-2020"] {
      background-color: #2e96ff;
    }

    span[class="color 2020-color-2030"] {
      background-color: #c06ad7;
    }

    span[class="color 2030-color-2030 "] {
      background-color: #67459b;
    }
  }

  .affordability-2030 {
    .minimum-color-fixed-plans,
    span[class="color 1gb-color-fixed-plans"] {
      background-color: #ffc300;
    }

    .average-color-fixed-plans,
    span[class="color 5gb-color-fixed-plans"] {
      background-color: #ff5733;
    }

    .maximum-color-fixed-plans,
    span[class="color 10gb-color-fixed-plans"] {
      background-color: #ab1e00;
    }

    span[class="color 1gb-color-mobile-broadband-plans"] {
      background-color: #67459b;
    }

    span[class="color 5gb-color-mobile-broadband-plans"] {
      background-color: #3753c8;
    }

    span[class="color 10gb-color-mobile-broadband-plans"] {
      background-color: #1e388c;
    }
  }

  .affordability-2020 {
    span[class="color 1gb-color-mobile-broadband-plans"] {
      background-color: #8c6dc1;
    }

    span[class="color 5gb-color-mobile-broadband-plans"] {
      background-color: #5d7be4;
    }

    span[class="color 10gb-color-mobile-broadband-plans"] {
      background-color: #4a63b2;
    }
  }

  @media (min-width: 800px) {
    & {
      .form-container {
        margin: 0 10px;
      }

      .cards-wrapper {
        .content-card {
          max-width: 100% !important;
        }
        .affordability-fixed-mobile-subscribers-gender,
        .affordability-fixed-mobile-subscribers-urbanization {
          max-width: 100% !important;
        }
      }
    }
  }
}
