.custom-legends {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  padding: 0 10px;
  .custom-legends-content {
    max-width: max-content;
    .legend-title {
      font-size: 14px;
    }
    & > div {
      margin-bottom: 20px;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
  & > div:not(:last-of-type) {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .legend-container {
    margin-left: 10px;
    & > div:not(:last-of-type) {
      margin-right: 10px;
    }
    .legend-sub-title {
      display: block;
      margin-bottom: 10px;
    }
    .legend-detail {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      .color {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        & + .color-label {
          font-size: 13px;
        }
      }
    }
  }
}
