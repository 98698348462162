.leaflet-control-layers .controller-button {
  display: flex;
  align-items: center;
}

.controller-loading {
  max-width: 16px;
  max-height: 16px;
  margin-right: 4px;
  padding: 4px;
}
