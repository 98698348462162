@import "../../../../variables.scss";

.hidden-legend {
  display: none;
}

.broadband-indicator-legend {
  font-size: 13px;
  color: $main-color;
  &.closed-legend {
    padding: 0;
  }

  .broadband-indicator-legend-header {
    display: flex;
    align-items: center;
    h4 {
      font-size: 14px;
      font-weight: 500;
    }

    .close-button {
      margin-left: 10px;
      font-size: 1.125rem;
      padding: 5px;
    }
  }

  i {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    opacity: 0.7;
    display: inline-block;
    vertical-align: top;
  }
}
