@import "../../variables.scss";

.about-modal-wrapper > div {
  cursor: pointer;
  color: $main-color;
}

.modal-contents-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  max-width: 850px;
  background-color: $white;
  padding: 14px;
  padding-right: 0;
  padding-top: 61px;
  border-radius: 5px;
  border: 0;
  line-height: 24px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  &.about {
    cursor: default;
  }

  .modal-header {
    width: calc(100% - 20px);
    display: flex;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background-color: $white;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    border-radius: 5px 5px 0 0;
    .close-button svg {
      fill: $main-color;
    }
    .about-title {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .main-contents-wrapper {
    overflow-y: scroll;
    height: 80vh;
    padding-top: 10px;
    padding-right: 14px;
    .about-paragraph {
      margin-bottom: 8px;
    }

    li,
    .about-paragraph {
      font-size: 14px;
    }

    .broadband-list-item,
    .link-list {
      margin: 0;
      display: block;
    }

    .broadband-list,
    .link-list {
      padding: 0;
    }

    .link-list,
    i {
      display: block;
      padding-top: 4px;
    }

    .link-list-item {
      padding-left: 0;
    }

    li {
      padding: 4px 0;
      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  @media (min-width: 600px) {
    padding-top: 71px;
    .modal-header {
      width: calc(100% - 28px);
      padding: 14px;
      .about-title {
        font-size: 28px;
      }
    }
  }

  @media (min-width: 1000px) {
    width: 100%;
  }

  @media (min-width: 1920px) {
    max-width: 900px;
    padding: 16px;
    padding-right: 0;
    padding-top: 77px;
    line-height: 24px;
    li,
    .about-paragraph {
      font-size: 16px;
    }

    li {
      padding: 4px 0;
      &:first-of-type {
        padding-top: 0;
      }
    }

    .link-list {
      padding-top: 8px;
    }

    .main-contents-wrapper {
      padding-right: 16px;
    }

    .modal-header {
      width: calc(100% - 32px);
      padding: 16px;
      margin-bottom: 10px;
    }

    .modal-header .about-title {
      font-size: 30px;
    }

    .about-paragraph {
      margin-bottom: 10px;
    }
  }
}

/* image preview  */
.rc-image-preview-root {
  .rc-image-preview-mask,
  .rc-image-preview-wrap {
    z-index: 9999;
  }
}

.rc-image-mask {
  font-size: 14px;
}

.rc-image {
  cursor: pointer;
}

body {
  .rc-image-preview-operations-wrapper {
    z-index: 10000;
  }

  .rc-image-preview-operations-operation:hover {
    color: $white;
  }
}
