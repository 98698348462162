.country-level-card {
  background-color: rgba(255, 255, 255, 0.5) !important;
  .content-card {
    .legend-container {
      .urban-color-2020,
      .male-color-2020,
      span[class="color average-/-5gb-color-2020"] {
        background-color: #2e96ff;
      }

      .rural-color-2020,
      .female-color-2020,
      span[class="color minimum-/-1gb-color-2020"] {
        background-color: #5eb4af;
      }

      .urban-color-2030,
      .male-color-2030,
      span[class="color minimum-/-1gb-color-2030"] {
        background-color: #67459b;
      }

      .rural-color-2030,
      .female-color-2030,
      span[class="color maximum-/-10gb-color-2020"] {
        background-color: #b800d8;
      }

      span[class="color average-/-5gb-color-2030"] {
        background-color: #3753c8;
      }

      span[class="color maximum-/-10gb-color-2030"] {
        background-color: #1e388c;
      }

      span[class="color 2020-color-2020"] {
        background-color: #5eb4af;
      }

      span[class="color 2030-color-2020"] {
        background-color: #2e96ff;
      }

      span[class="color 2020-color-2030"] {
        background-color: #c06ad7;
      }

      span[class="color 2030-color-2030"] {
        background-color: #67459b;
      }
    }
  }

  @media (min-width: 860px) {
    .charts-wrapper > div {
      max-width: 50% !important;
    }

    .cards-wrapper .content-card {
      max-width: calc(50% - 24px) !important;
    }
  }
}
