@import "../../variables.scss";

.report {
  padding: 10px;
  background-color: $light-blue !important;
  h2,
  h3,
  th {
    font-weight: 500;
  }

  h2 {
    font-size: 24px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .content-card rect {
    cursor: pointer;
  }

  .floating-button {
    position: fixed;
    right: 11px;
    bottom: 10vh;
    color: #fff;
    background-color: $blue;
    &:hover {
      background-color: $dark-blue;
    }
  }

  .report-main-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .report-main-content .card-title {
    padding: 0 10px;
  }

  .card {
    margin-bottom: 20px;
    border-radius: 8px;
    &.main-card {
      padding: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .charts-wrapper {
      margin-top: 10px;
      & > div {
        width: 100%;
        page-break-inside: avoid;
      }
    }

    .custom-legends + div {
      padding: 0 10px;
      width: unset !important;
    }

    .card-header > div > span {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }

    .cards-wrapper,
    .charts-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .custom-legends-content {
        display: flex;
      }

      .content-card {
        width: 100%;
        max-width: 100%;
        margin: 10px 0;
        padding: 0;
        border-radius: 8px;
        h3 {
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 800px) {
    h2 {
      font-size: 28px;
    }

    .card {
      margin-bottom: 30px;
      &.main-card {
        padding: 20px;
      }

      .cards-wrapper .content-card {
        margin: 12px;
      }
    }
  }

  @media (min-width: 1090px) {
    padding: 10px 70px;
  }

  @media (min-width: 1140px) {
    .cards-wrapper .content-card {
      max-width: calc(50% - 72px);
    }
  }
}

.document-mask {
  width: 100%;
  height: 100vh;
  background-color: $light-blue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1099;
  &,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div {
    flex-direction: column;
    p {
      margin-top: 10px;
    }
  }
}

// Generated PDF styles
.customized-report {
  .card .custom-legends + div,
  .subnational-level .affordability-legends + div {
    width: 1201px + 135px !important;
    max-width: 1201px + 135px !important;
    margin: 0 auto;
  }

  .report-main-content {
    width: 1285px + 135px !important;
  }

  .report-map,
  .report-map + div {
    width: 633.5px + 135px !important;
  }

  .report-map {
    height: 711px !important;
  }
}

.report-to-pdf {
  .document-mask {
    display: none !important;
  }
  .report {
    padding-bottom: 0;
  }

  & > .card {
    flex-wrap: nowrap;
  }

  .report-map {
    align-self: center;
    margin-right: 0;
  }

  .no-data,
  .empty-chart,
  .floating-button,
  .form-container {
    display: none !important;
  }

  .valid-chart > div,
  .valid-chart,
  .cards-wrapper .content-card,
  .charts-wrapper > div,
  .affordability-fixed-mobile-subscribers-gender > div,
  .affordability-fixed-mobile-subscribers-urbanization > div,
  .valid-chart svg {
    width: 100%;
    max-width: 100% !important;
  }

  .report-main-content {
    page-break-after: always;
  }

  .content-card {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: start;
    page-break-inside: avoid;
  }

  @media (min-width: 800px) {
    .card .cards-wrapper .content-card {
      max-width: 100% !important;
    }
  }
}

@media print {
  @page {
    size: 1440px;
    margin: 0 !important;
    .no-data,
    .empty-chart {
      display: none;
    }
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  }

  .report-to-pdf {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
    .report {
      padding: 10px !important;
      .overview-card {
        .section h3 {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
