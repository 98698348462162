@import "../../variables.scss";

ul .menu-button.theme-button {
  justify-content: center;
}

ul a:hover {
  text-decoration: none;
}

.mobile-menu li {
  font-size: 14px;
  color: $main-color;
  text-transform: uppercase;
  font-weight: 500;
}

.header {
  flex-wrap: 1;
  margin-bottom: 56px;
  header > div {
    min-height: 56px;
  }

  a {
    display: flex;
    text-decoration: unset;
  }

  button {
    color: $white;
    text-wrap: nowrap;
    height: max-content;
    &:disabled {
      color: var(--white);
    }
  }

  .activated-button {
    background-color: rgba(225, 225, 225, 0.2);
  }

  .filter-button {
    color: #f2ae3f;
  }

  .css-96uuyl {
    width: unset;
  }

  .title {
    display: none;
    color: $white;
    font-size: 1.25rem;
  }

  .broadband-mapping-logo {
    margin-right: 10px;
    background: $white;
    border-radius: 50%;
    padding: 5px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  .header-button-wrapper {
    display: none;
    .about-button {
      font-size: 14px;
      text-transform: uppercase;
      transition: all $transition;
    }
    .theme-button:hover,
    .header-button:hover {
      background-color: $dark-blue;
    }
  }

  .header-separator {
    flex-grow: 1;
  }

  @media (min-width: 601px) {
    .mobile-menu {
      display: none;
    }

    .header-button-wrapper {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 901px) {
    .title {
      display: block;
    }

    .broadband-mapping-logo {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    margin-bottom: 64px;
    header > div {
      min-height: 64px;
    }

    .title {
      font-size: 1.5rem;
    }

    .about-button {
      font-size: 16px;
    }
  }
}
