// Colors
$white: #fff;
$light-grey: #9a9a9a;
$blue: #1d76d2;
$dark-blue: #1565c0;
$url-color: #0078a8;
$light-blue: #e0eaf3;
$main-color: rgba(0, 0, 0, 0.87);

// Other variables
$transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
