/* Colors */
@import "./variables.scss";

/* Body Styles */
#root {
  background-color: #aad3df;
}
body {
  margin: auto;
  * {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }
}

#root .chip {
  height: 28px;
  padding-left: 0;
  padding-right: 0;
  color: $blue;
  font-weight: 600;
  border: 2px solid $blue;
  background-color: $white;
  transition: all $transition;
  &:hover,
  &:focus {
    color: $white;
    background-color: $blue;
  }
}

.leaflet-pane.leaflet-marker-pane,
.leaflet-pane.leaflet-shadow-pane {
  display: none;
}

.map-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .leaflet-container {
    width: 100%;
  }
  .custom-layer {
    display: none;
  }
}

.leaflet-interactive {
  transition: all $transition;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip,
body .leaflet-control-layers-expanded {
  color: $main-color;
}

.drawer-map-tootip .title {
  margin: 0;
}

a,
.leaflet-container a,
.link-list-item a {
  color: $url-color;
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
  text-decoration: none;
  transition: color $transition;

  &:hover {
    color: $main-color;
    text-decoration: underline;
  }
}

/* Links */
#root {
  .leaflet-popup-close-button:hover {
    text-decoration: none;
  }
}

/* SVG Paths */
svg {
  path {
    cursor: pointer;
  }
}

nobr {
  white-space: pre-wrap;
}

table + br {
  display: none;
}

/* Lists */
#root ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Leaflet Map Styles */
.leaflet-left {
  margin-right: 60px;
}

.leaflet-right {
  margin-left: 60px;
}

.leaflet-popup,
.leaflet-tooltip {
  z-index: 999;
}

.leaflet-popup {
  .leaflet-popup-content {
    margin: 13px 10px;
    line-height: 1.4;
    font-size: 13px;
    .title {
      margin: 0px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

    .featureInfo {
      margin: 0;
      width: 100%;
    }

    .leaflet-popup-content {
      margin: 0;
    }

    &.natural-hazard-popup {
      margin: 0;
    }
  }
}

// Hide or show popup depending on the content
.leaflet-popup-pane {
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

.dark-map {
  .leaflet-pane.leaflet-tile-pane {
    filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
      saturate(0.3) brightness(0.7);
  }
}

iframe table {
  width: auto !important;
}

/* Using "!important" to override inline styles */
.leaflet-container {
  .leaflet-popup-close-button {
    top: -6px !important;
    right: calc(0% - 8px) !important;
    width: 18px !important;
    height: 18px !important;
    color: #757575 !important;
    border-radius: 50% !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 40) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    transition: all $transition;

    &:hover {
      background: $blue !important;
      color: #fff !important;
    }
  }
}

.transparent-pane {
  .leaflet-indicator-pane,
  .leaflet-drawer-map-pane {
    opacity: 0.5;
  }
}

.leaflet-pane {
  &.leaflet-wind-pane {
    z-index: 399;
  }

  &.leaflet-indicator-pane,
  &.leaflet-drawer-map-pane {
    z-index: 400;
  }

  &.leaflet-background-layers-pane {
    z-index: 401;
  }
}

.tooltip {
  li,
  h4 {
    color: $main-color;
  }
  li {
    display: flex;
    padding: 2.5px 0;

    b {
      margin-left: 5px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    ul {
      padding: 0;
    }

    a {
      display: block;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }
  }
}

#wmsContainer {
  cursor: grab;
}

/* Disabled Links */
.leaflet-bar {
  a.leaflet-disabled:hover {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb;
  }
}

/* Shadows and Borders */
.legend,
.leaflet-control-zoom.leaflet-bar.leaflet-control,
.leaflet-control-layers.leaflet-control-layers-expanded.leaflet-control.custom-controller-wrapper {
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  border: 0;
}

/* Subsection Styles */
.sub-section {
  margin-left: 16px;
}

/* Indicator Controller Styles */
.indicator-controller-wrapper {
  .indicator-wrapper {
    .controller-title {
      color: $main-color;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .custom-controller-wrapper {
    .controller-button {
      margin-left: 0;
    }
  }
}

/* Close Button Styles */
.close-button svg {
  fill: $main-color;
}

/* Controller Button Styles */
div .controller-button {
  margin-left: 0;
  span {
    font-size: 13px;
  }

  .toggle-button {
    padding: 2px;
    margin-right: 3px;
  }
}

/* Zoom Control Styles */
.leaflet-control-zoom {
  display: flex;
  align-items: center;
  left: calc(50vw - 30px);

  &.leaflet-bar.leaflet-control {
    .leaflet-control-zoom-out,
    .leaflet-control-zoom-in {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 0;
      color: $main-color;
      transition: all $transition;
      &:hover {
        color: $white;
        background: $blue;
        text-decoration: none;
      }
    }

    position: absolute;
    bottom: 15px;
    overflow: hidden;
    border-radius: 4px;
    float: unset;
    clear: unset;
    max-width: 60px;
    z-index: 1000;
  }
}

/* Leaflet Container Styles */
.leaflet-container {
  height: calc(100vh - 56px);
}

.wind-speed-table {
  th:first-of-type,
  td:first-of-type {
    display: none;
  }
}

.tooltip {
  label {
    cursor: pointer;
    color: $url-color;
    transition: color $transition;

    .show-more {
      display: inline;
    }

    .show-less {
      display: none;
    }
    &:hover {
      color: $main-color;
    }
  }

  // Show more checkbox
  [type="checkbox"] + h4 .feature-name {
    font-weight: 700;
  }

  [type="checkbox"]:checked {
    & + h4 {
      .feature-name {
        font-weight: 400;
      }

      .natural-hazard-name {
        display: none;
      }
    }

    ~ label .show-more {
      display: none;
    }

    ~ .tooltip-more-info {
      visibility: visible;
      height: unset;
    }

    ~ label .show-less {
      display: inline;
    }
  }

  .show-more-checkbox {
    display: none;
  }

  .tooltip-more-info {
    overflow: hidden;
    visibility: hidden;
    height: 0;
    h2 {
      margin: 0;
      margin-bottom: 10px;
    }
    table {
      border-collapse: collapse;
    }
    tr:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 2.5px 0;
    }

    hr {
      border-width: 0;
      margin: 0;
      border-color: rgba(0, 0, 0, 0.12);
      border-bottom-width: thin;
    }

    br {
      display: none;
    }
  }
}

/* Legend Styles */
.legend {
  font-size: 14px;
  border-radius: 4px;
  line-height: 18px;
  padding: 6px 8px;
  background-color: $white;
  color: $main-color;
}

.legend .legend-title {
  margin: 0;
  margin-bottom: 3px;
  font-size: 14px;
}

@media (max-device-width: 600px) {
  .leaflet-container {
    height: calc(100vh - 110px);
  }
}

@media (min-width: 601px) {
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    bottom: 0;
  }

  .mobile-menu {
    display: none;
  }
}

@media (max-width: 830px) {
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    display: none;
  }
}

@media (min-width: 900px) {
  .map-container {
    display: flex;
    flex-direction: row;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .leaflet-container {
    height: calc(100vh - 136px);
  }
}

@media (min-width: 1920px) {
  .leaflet-container {
    height: calc(100vh - 64px);
  }
}
