/* Import external stylesheet */
@import "../../../variables.scss";

/* General styles for search input */
.search-input {
  &:hover {
    border: none;
  }
  input {
    color: $white;
  }
}

/* Styles for auto-completed input */

.header {
  .css-96uuyl {
    transition: all $transition;
  }

  .auto-completed-input {
    width: 175px;

    /* Remove borders on hover for auto-completed input */
    &:hover {
      border: 0;
      * {
        border: none;
      }
    }

    /* Responsive styles for auto-completed input in header */
    &,
    & input[role="combobox"] {
      width: 100%;
    }

    /* Styles for fieldset in auto-completed input */
    fieldset {
      border: 0;
    }

    /* Styles for loading icon in auto-completed input */
    .loading-icon {
      color: $white;
      width: 10px;
      height: 10px;
    }

    .search-input > div {
      padding: 2.5px 4.5px;
      font-size: 14px;

      /* Styles for search input button in auto-completed input */
      input + div > button {
        visibility: visible;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    @media (min-width: 281px) {
      width: 250px;
      & input[role="combobox"] {
        max-width: 200px;
      }
    }

    /* Responsive styles for larger screens */
    @media (min-width: 1920px) {
      width: 300px;
      & input[role="combobox"] {
        max-width: 100%;
      }

      .search-input > div {
        padding: 5px 9px;
        font-size: 16px;
      }

      .loading-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
