@import "../../../variables.scss";

.custom-controller-wrapper.indicator-controller-wrapper {
  padding: 39px 0 6px 6px;
  margin-top: 0;
  margin-right: 0;
  h4 {
    margin: 0;
  }

  .divider-line {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .panel-header {
    .panel-title {
      display: flex;
      align-items: center;
      margin-right: 0;
      font-weight: 500;
    }

    .reset-button {
      font-size: 10px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  &.closed-panel {
    padding: 0;
    overflow-y: unset;
    max-height: 40px;
  }

  &.closed-panel {
    .panel-header {
      display: none;
    }

    .indicator-wrapper {
      padding: 0;
      min-width: 0;
      padding: 0;
      overflow-y: unset;
      max-height: 40px;
      div {
        height: 0;
        width: 0;
      }
    }

    .indicator-controller {
      width: 0;
      height: 0;
      display: none;
    }
  }

  .indicator-wrapper {
    min-width: 245px;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 8px;
    padding-top: 6px;
    .indicator-controller {
      width: 100%;
    }
  }

  @media (min-width: 1920px) {
    & {
      padding-left: 8px;
      padding-right: 8px;
      &.long-panel {
        padding-right: 0;
      }
      .custom-controller-wrapper.closed-panel {
        padding: 0;
      }

      .indicator-wrapper {
        max-height: calc(511px - 45px);
        overflow-y: auto;
      }
    }
  }
}
