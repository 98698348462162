.slider-item {
  padding: 16px;
  padding-right: 30px;
  span {
    font-size: 12px;
    font-weight: 500;
  }
  .slider-label {
    font-size: 14px;
    margin: 0;
  }
  .slider-item-wrapper {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 601px) {
  .slider-item .slider-label {
    font-size: 16px;
    margin: 0;
  }
}
