@import "../../../../variables.scss";

.custom-controller-wrapper.bg-layers-info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 65px 0 14px 14px;
  margin-left: 0;
  margin-top: 0;
  .bg-layers-info-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: calc(390px - 14px);
    background-color: $white;
    justify-content: space-between;
    padding: 4px 14px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  }

  a {
    word-wrap: anywhere;
  }

  .link-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 8px 0;
    span,
    b {
      margin-right: 5px;
    }
  }

  &,
  .bg-layers-wrapper {
    max-width: 390px;
    max-height: 180px;
  }

  .bg-layers-wrapper {
    overflow-y: auto;
    padding: 10px 14px 0 0;
    .info-section {
      &:not(:last-of-type) {
        margin-bottom: 14px;
      }

      .bg-layers-category {
        text-transform: uppercase;
        margin: 8px 0 0;
        font-weight: 500;
        &:first-of-type {
          margin-top: 0;
        }
      }

      .bg-layers-article {
        &:last-of-type .bg-layers-paragraph:last-of-type {
          margin-bottom: 0;
        }

        .bg-layers-paragraph {
          margin: 0 0 8px;
          ol {
            margin: 0;
            padding-left: 28px;
          }
        }
      }
    }
  }

  .info-link,
  ol,
  .bg-layers-paragraph {
    font-size: 14px;
  }

  &.closed-info {
    padding: 0;
    .bg-layers-wrapper {
      max-width: 40px;
      max-height: 40px;
      overflow: hidden;
      border-radius: 5px;
      padding: 0;
    }
  }

  &.opened-info {
    padding: 50px 0 16px 16px;
    .panel-header {
      padding: 10px 14px;
      max-width: calc(390px - 14px);
    }

    .panel-title {
      font-size: 16px;
    }
  }

  @media (min-width: 366px) {
    padding: 48px 0 14px 14px;
  }

  @media (min-width: 1920px) {
    &,
    .bg-layers-wrapper {
      max-height: 300px;
      max-width: 500px;
    }

    .bg-layers-wrapper {
      padding-right: 16px;
    }

    .info-link,
    ol,
    .bg-layers-paragraph {
      font-size: 16px;
    }

    .info-section:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .bg-layers-category {
      text-transform: uppercase;
      margin-top: 10px;
      font-weight: 500;
    }

    &.opened-info {
      .panel-header {
        width: 100%;
        max-width: calc(500px - 16px);
        padding: 10px 16px;
        .panel-title {
          font-size: 20px;
        }
      }
    }

    .bg-layers-article {
      .bg-layers-paragraph {
        margin-bottom: 10px;
      }

      ol {
        padding-left: 30px;
      }
    }
  }
}
