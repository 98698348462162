@import "../../variables.scss";

div .open-panel-button {
  width: 40px;
  height: 40px;
  padding: 0;
  color: $main-color;
  min-width: unset;
  &:hover {
    background-color: $blue;
    color: $white;
  }
}
