.custom-controller-wrapper.closed-controller,
.custom-controller-wrapper.bg-layers-controller {
  margin: 0;
  margin-bottom: 15px;
  &.bg-layers-controller {
    position: relative;
    padding-right: 0;
    padding: 38px 0 6px 8px;
    .controller-loading {
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
      padding: 4px;
    }
    .long-label {
      margin-right: 0;
    }
    span {
      max-width: 155px;
    }

    .bg-layers-wrapper {
      max-height: 150px;
      overflow-y: auto;
      flex-wrap: nowrap;
      padding-bottom: 0;
    }

    .bg-layers-header-wrapper,
    .controller-button {
      display: flex;
      align-items: center;
    }

    .bg-layers-header-wrapper,
    &-header {
      justify-content: space-between;
    }

    .bg-layers-wrapper {
      padding-right: 8px;
      padding-top: 5px;
    }
  }
  &.opened-controller .bg-layers-header-wrapper {
    max-width: 260px;
  }

  &.closed-controller {
    padding: 0;
    .bg-layers-wrapper {
      display: none;
    }
  }

  .population-panel {
    z-index: 399;
  }

  .bg-layers-header-wrapper .bg-layers-legend-title,
  .mobile-legend-wrapper .close-button {
    display: none;
  }

  .mobile-legend-wrapper {
    position: relative;
    .panel-header {
      height: 28px;
      left: -8px;
      padding: 5px 12px;
      box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    }
  }

  @media (min-width: 601px) {
    &.closed-controller,
    &.bg-layers-controller {
      margin-bottom: 0;
    }
  }

  @media (min-width: 795px) {
    &.bg-layers-controller {
      > div {
        display: flex;
      }

      &-header {
        display: flex;
        align-items: center;
      }

      .bg-layers-header-wrapper .bg-layers-legend-title {
        display: block;
      }

      .mobile-legend-wrapper {
        padding-top: 0;
        .panel-header {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .bg-layers-controller-header {
      height: 28px;
    }

    .bg-layers-controller {
      padding: 38px 0px 6px 8px;
    }

    &.closed-controller {
      padding: 0;
    }

    .mobile-legend-wrapper {
      position: relative;
      padding-top: 0px;
    }
  }
}
