@import "../../../variables.scss";
.drawer {
  box-shadow: 1px 1px 1px $main-color;
  border-radius: 0;
  padding: 16px;
  padding-top: 48px;
  padding-right: 0;
  padding-left: 0;
  position: absolute;
  top: 40%;
  z-index: 1001;
  height: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
  .panel-header {
    padding: 10px 14px;
    width: calc(100% - 28px);
    .panel-title {
      font-size: 16px;
    }
  }

  .drawer-content {
    padding: 10px 0;
    overflow-y: auto;
    padding-bottom: 10px;
    height: calc(60vh - (64px + 60px));

    .controller-title {
      color: $main-color;
      font-weight: 500;
      font-size: 12px;
    }

    .drawer-form-control {
      padding: 16px;
      label {
        margin-left: 0;
        .controller-button span {
          font-size: 14px;
        }
      }
    }

    .slider-container {
      .controller-title {
        padding-top: 16px;
      }

      .controller-title,
      .note {
        padding-left: 16px;
        padding-right: 16px;
      }

      .note {
        font-size: 14px;
        padding-bottom: 12px;
      }

      .slider-item:first-of-type {
        padding-top: 0px;
      }
    }
  }

  &.opened-drawer {
    width: 100%;
    border-radius: 0;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.closed-drawer {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .divider-line {
    max-width: calc(100% - 32px);
    margin-left: 16px;
  }

  @media (min-width: 254px) {
    .drawer-content {
      padding: 0;
    }
  }

  @media (min-width: 900px) {
    position: relative;
    top: unset;
    max-width: calc(410px - 32px);
    height: unset;
    .drawer-content {
      height: calc(100vh - 130px);
      .controller-title {
        font-size: 14px;
      }
      .controller-button span {
        font-size: 16px;
      }
    }
  }
}
