@import "../../../../variables.scss";

.legend.bg-layers-legend {
  box-shadow: none;
  max-width: calc(244.78px - 8px);
  max-height: calc(156px - 7px);
  overflow-y: auto;
  padding-right: 0;
  padding-bottom: 0;
  h4 {
    font-size: 14px;
    font-weight: 500;
  }

  .bg-layers-legend-wrapper {
    padding-right: 8px;
    .list,
    .list-item {
      padding: 0;
    }

    .list-item,
    div,
    span {
      font-size: 13px;
      color: $main-color;
    }

    .legend-item {
      width: 100%;
      &:not(:last-of-type) {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .electricity-line .list,
    .road .list,
    .list-item,
    .road div {
      display: flex;
      align-items: center;
    }

    .electricity-line .list,
    .road .list {
      flex-wrap: wrap;
    }

    .electricity-line .list-item {
      flex-basis: 50%;
    }

    .electricity-line-status-color {
      height: 6px;
    }

    .long-bar {
      width: 20px;
      display: block;
      margin-right: 5px;
    }

    .round-status {
      width: 9px;
      height: 9px;
      display: block;
      border-radius: 50%;
      margin-right: 5px;
    }

    /* Fibre optics */
    .live {
      background-color: #ee9600;
    }

    .under-construction {
      background-color: #d7471b;
    }

    /* Relevant facilities */
    .legend-icon-wrapper {
      display: flex;
      align-items: center;
      .legend-icon {
        width: 100%;
        max-width: 20px;
      }
    }

    /* Road */
    .road {
      .list {
        row-gap: 5px;
        .list-item {
          flex-basis: auto;
          .road-status-color {
            width: 58px;
            background-color: #f0a200;
            border-top: 0.25px solid $light-grey;
            border-bottom: 0.25px solid $light-grey;
          }
          .footway,
          .path,
          .sidewalk,
          .crossing,
          .cycleway,
          .bridleway,
          .track,
          .steps {
            height: 0 !important;
            border-width: 2px;
            background-color: $white !important;
          }

          .footway,
          .path,
          .sidewalk,
          .crossing,
          .cycleway,
          .steps {
            border-style: dotted;
          }

          .bridleway,
          .track {
            border-style: dashed;
          }

          .steps {
            border-width: 3px;
            border-color: #e87b6f;
            border-style: dashed;
          }

          .footway,
          .path,
          .sidewalk {
            border-color: #eea59d;
          }

          .cycleway {
            border-color: #596cfb;
          }

          .bridleway {
            border-color: #619d46;
          }

          .track {
            border-color: #ac8327;
          }

          .primary_link,
          .secondary_link,
          .tertiary_link,
          .trunk_link,
          .motorway_link {
            height: 14px !important;
            border: 1px solid grey;
            border-right: 0;
            border-left: 0;
          }
        }
      }
    }
  }
}

/* Natural Hazard Styles */
.natural-hazard {
  span {
    margin-right: 5px;
  }

  label {
    margin-right: 0;
  }

  li:not(:first-of-type) {
    span,
    svg {
      margin-top: 5px;
    }
  }

  .legend-icon-wrapper {
    width: 25px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
  }

  .green,
  .blue {
    width: 15px;
    height: 15px;
  }

  .yellow {
    color: #f6da2a;
    width: 18px;
    height: 18px;
  }

  .green {
    color: #87e616;
  }

  .blue {
    color: #1d76d2;
  }

  .red {
    color: #d74606;
    width: 25px;
    height: 25px;
  }

  .orange {
    color: #e3a932;
    width: 22px;
    height: 22px;
  }

  .circle {
    border-radius: 50%;
  }

  .square,
  .circle {
    border: 1px solid $main-color;
  }

  .circle-blue,
  .square-blue,
  .circle-green,
  .square-green {
    width: 8px;
    height: 8px;
  }

  .circle-yellow,
  .square-yellow {
    width: 12px;
    height: 12px;
  }

  .circle-orange,
  .square-orange {
    width: 14px;
    height: 14px;
  }

  .circle-red,
  .square-red {
    width: 18px;
    height: 18px;
  }
}

/* Earthquakes Magnitude Styles */
.earhquakes-magnigutude span {
  width: 15px;
  height: 15px;
  background-color: #e38089;
  margin-right: 5px;
  border: 2px solid $main-color;
}

/* Wind Speed Styles */
.wind-speed {
  li {
    display: flex;
    flex-direction: column;
    width: 19px;
    .label {
      margin-left: 65px;
    }

    .status-color {
      border: 0;
      opacity: 0;
      height: 19px;
    }
  }

  .list-item {
    &:nth-of-type(3) {
      height: 0;
    }
    &:nth-of-type(2) .label {
      position: relative;
      top: 5px;
    }
  }
}

@media (min-width: 1920px) {
  .bg-layers-header-wrapper {
    max-width: 240px;
  }
}
